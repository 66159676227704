.patientActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 20%;
  margin: auto 50px;
}

.containerPatient {
  width: 90%;
  height: 320px;
  padding: 0 50px;
  position: relative;
  margin: 50px auto auto 6%;
  display: flex;
  flex-direction: row;
  background-color: rgb(255, 255, 255);
  border: 1px solid #0081c1;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.containerPatientClosed {
  width: 90%;
  height: 320px;
  padding: 0 50px;
  position: relative;
  margin: 50px auto auto 6%;
  display: flex;
  flex-direction: row;
  background-color: #ef9a53;
  border: 1px solid #0081c1;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.react-datepicker__input-container input {
  display: block;
  width: 90%;
  margin: 40px auto;
  height: calc(1.5em + 1.3rem + 2px);
  padding: 0.65rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e2e5ec;
  border-radius: 4px;
}

.ahmadSaleh nav .sidenav---sidenav---_2tBP {
  position: absolute !important;
  right: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  z-index: 1006 !important;
  min-width: 64px !important;
  transition: 0.2s !important;
}

.sidenav---sidenav---_2tBP .sidenav---expanded---1KdULP {
  width: 500px;
  height: 100%;
}

.patientInfoDetailsVisit {
  display: flex;
}
.patientInfo {
  display: flex;
  justify-content: space-between;
  margin: auto 0;
  width: 100%;
}

.patientInfoDetailsPlace {
  display: flex;
  justify-content: space-around;
  width: max-content;
  margin-bottom: 30px;
}

.sidenav---icon-bar---u1f02 {
  border-color: black !important;
  color: black !important;
}
.sidenav---sidenav-toggle---1KRjR {
  color: black !important;
}

.sidenav---sidenav---_2tBP,
.sidenav---collapsed---LQDEv {
  height: 140% !important;
}

.patientAlgNone {
  display: none;
}

/* Tooltip text */
.tooltiptext, .tooltiptext1, .tooltiptext2, .tooltiptext3, .tooltiptext4 {
  visibility: hidden;
  width: 120px;
  margin: 90px 0px 0 50px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the tooltip text - see examples below! */
  position: absolute;
  z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.inactiveImage:hover .tooltiptext, .inactiveImage:hover .tooltiptext1, .inactiveImage:hover .tooltiptext2, .inactiveImage:hover .tooltiptext3
,.inactiveImage:hover .tooltiptext4 {
  visibility: visible;
}

.tooltipItem {
  position: absolute;
  width: 60px;
  height: 90px;
  right: 0;
  top: 20%;
  border-radius: 120px 0 0 120px;
  background-color: #eb455f;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.tooltipItem1 {
  position: absolute;
  width: 60px;
  height: 90px;
  display: flex;
  right: 0;
  justify-content: center;
  top: 50%;
  align-items: center;
  border-radius: 120px 0 0 120px;
  background-color: #fca71f;
}

.tooltipItem1:hover {
  position: absolute;
  width: 180px;
  height: 90px;
  right: 0;
  top: 50%;
  border-radius: 120px 0 0 120px;
  background-color: #fca71f;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.tooltipItem:hover {
  position: absolute;
  width: 180px;
  height: 90px;
  right: 0;
  top: 20%;
  border-radius: 120px 0 0 120px;
  background-color: #eb455f;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

/* =======================-------------------------------------------------------=========================== */

.tooltipItemClosed {
  position: absolute;
  width: 60px;
  height: 90px;
  right: 0;
  top: 20%;
  border-radius: 120px 0 0 120px;
  background-color: white;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
}

.tooltipItem1Closed {
  position: absolute;
  width: 60px;
  height: 90px;
  display: flex;
  right: 0;
  justify-content: center;
  top: 50%;
  align-items: center;
  border-radius: 120px 0 0 120px;
  background-color: white;
}

.tooltipItem1Closed:hover {
  position: absolute;
  width: 180px;
  height: 90px;
  right: 0;
  top: 50%;
  border-radius: 120px 0 0 120px;
  background-color: white;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.tooltipItemClosed:hover {
  position: absolute;
  width: 180px;
  height: 90px;
  right: 0;
  top: 20%;
  border-radius: 120px 0 0 120px;
  background-color: white;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.curve:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 250px;
  border-top: 1px solid #0081c1;
  border-radius: 180%;
  left: 50%;
  top: 0;
}
.curve:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 250px;
  border-bottom: 1px solid #0081c1;
  border-radius: 180%;
  left: 0%;
  margin-top: 0%;
}

.curveClosed:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 250px;
  border-top: 1px solid white;
  border-radius: 180%;
  left: 50%;
  top: 0;
}
.curveClosed:before {
  content: "";
  position: absolute;
  width: 50%;
  height: 250px;
  border-bottom: 1px solid white;
  border-radius: 180%;
  left: 0%;
  margin-top: 0%;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 43% 0%;
  }
  50% {
    background-position: 58% 100%;
  }
  100% {
    background-position: 43% 0%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 43% 0%;
  }
  50% {
    background-position: 58% 100%;
  }
  100% {
    background-position: 43% 0%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 43% 0%;
  }
  50% {
    background-position: 58% 100%;
  }
  100% {
    background-position: 43% 0%;
  }
}

@keyframes heartbeat {
  0% {
    transform: scale(0.92);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.92);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.92);
  }
  100% {
    transform: scale(0.92);
  }
}

.tooltipItem span,
.tooltipItem1 span,
.tooltipItemClosed span,
.tooltipItem1Closed span {
  display: none;
}
.tooltipItem:hover span,
.tooltipItem1:hover span,
.tooltipItemClosed:hover span,
.tooltipItem1Closed:hover span {
  display: inline;
  font-size: 17px;
  width: 89%;
  font-weight: 500;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: auto 10px;
}

.patientAlg {
  display: block;
  animation: heartbeat 1s infinite;
  margin: auto;
  top: 50%;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.patientAlg1 {
  display: block;
  margin: auto;
  top: 50%;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

.patientAlg:hover {
  display: block;
  animation: none;
  margin: auto;
  top: 50%;
  font-size: 20px;
  font-weight: 700;
  color: white;
}

/* ------------------------------------------------------------ */

.patientAlgClosed {
  display: block;
  animation: heartbeat 1s infinite;
  margin: auto;
  top: 50%;
  font-size: 20px;
  font-weight: 700;
  color: #eb455f;
}

.patientAlg1Closed {
  display: block;
  margin: auto;
  top: 50%;
  font-size: 20px;
  font-weight: 700;
  color: #ff9c00;
}

.patientAlgClosed:hover {
  display: block;
  animation: none;
  margin: auto;
  top: 50%;
  font-size: 20px;
  font-weight: 700;
  color: #eb455f;
}

.image-container {
  position: relative;
  margin-top: 12px;
}

.overlay {
  position: absolute;
  width: 50%;
  margin: auto;
  border: solid 1px inherit;
  border-radius: 0 0 20px 20px;
  bottom: 50%;
  left: 0;
  right: 0;
  top: 90%;
  /* background-color: rgba(0, 0, 255, 0.5); */
  box-shadow: 0px -60px 20px 60px rgba(2, 2, 36, 0.733);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding-top: 0;
  color: #ef9a53;
  font-size: 25px;
  line-height: 55.63px;
  font-weight: 600;
}

/* ----------------------------------------------- SideBar Style ----------------------------------------------- */
.sidebar {
  position: fixed;
  top: 0;
  left: -300px;
  width: 350px;
  height: 100%;
  background-color: #ffffff;
  z-index: 999;
  transition: all 0.3s ease;
}

.activeImage {
}

.inactiveImage {
  width: 50px;
  height: 50px;
  background: #373737;
  border: 1px solid;
  border-radius: 10px;
  margin: auto;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar.active,
.sidebar.active span {
  left: 0;
  color: black !important;
}

.sidebar.inactive {
  position: fixed;
  top: 0;
  left: -300px;
  width: 90px;
  height: 100%;
  background-color: #343a40 !important;
  z-index: 999;
  transition: all 0.3s ease;
}

.sidebar.inactive {
  left: 0;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar li {
  margin-bottom: 10px;
}

.sidebar a {
  display: flex;
  align-items: center;
  color: #fff;
  text-decoration: none;
  position: relative;
  height: 50px;
  padding: 0 20px;
  transition: all 0.3s ease;
}

.dddf {
  margin: auto;
  width: 320px;
  height: 60px;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  text-align: left;
  align-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px 0px #0081c11a;
  border: 0.5px solid #ffffff;
  border-radius: 5px;
}

.itemLep {
  position: absolute;
  width: 5px;
  height: 55px;
  margin: auto 0;
  background: #029b9b;
  border-radius: 0px 2px 2px 0px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.dddf .sds {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #029b9b;
  right: 10%;
  border-radius: 50%;
}


.dddf .sdsInactive {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #313838;
  right: 10%;
  border-radius: 50%;
}

.dddf span {
  align-self: center;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
  margin: auto auto auto 10px;
}

.dddf span:hover {
  color: #007bff;
}

.react-datepicker__input-container input {
  width: 90%; margin: auto auto 10px auto; padding: 10px;
  align-content: center;
  align-items: center;
  box-shadow: 0px 1px 5px 0px #0081c11a;
  border: 0.5px solid #ffffff;
  border-radius: 5px;
  position: relative;
  flex: 1;
  height: 100%;
  font-size: 16px;
}

.iconBttttn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background-color: #af5858;
  color: white;
  border: none;
  border-radius: 0 5px 5px 0;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  margin: 0 auto; position: relative

}

.dddf:hover {
  width: 320px;
  height: 60px;
  display: flex;
  box-shadow: 0px 1px 5px 0px #0081c11a;
  border: 0.5px solid #343a40;
  border-radius: 5px;
  color: #007bff;
  cursor: pointer;
}

/* .sidebar.inactive:hover {
  background-color:  #0081c1;
} */

.inactiveImage:hover {
  background-color: #fca71f;
}

.sidebar a .activeImage {
  margin-right: 15px;
  font-size: 20px;
}

.sidebar svg + span {
  position: absolute;
  left: -9999px;
  visibility: hidden;
  margin-left: 150px;
  transition: all 0.3s ease;
}

.sidebar.active a svg + span {
  left: 50px;
  visibility: visible;
}

.sidebar .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
}

.main-content {
  margin-left: 0;
  transition: all 0.3s ease;
}

.main-content.active {
  margin-left: 300px;
}

.pg-viewer-wrapper {
    overflow-y: unset !important;
}

.photo-viewer-container {
    width: unset !important;
    height: unset !important;
}

.video-container video {
    width: 100% !important;
}
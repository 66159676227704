.logoImg {
    width: 60%;
    margin: auto;

}

.logoWrap {
    text-align: center;
    margin: 0 auto 4rem auto;
    width: 30%;
}

.form {
    width: 30%;
    margin: auto;
}

.formText {
    margin: 50px auto;
    color: #595d6e;
}

.bttnWrap {
    margin: auto;
    display: inline-block;
    position: inherit;
}

.buttn {
    margin: auto !important;
    text-align: center !important;
}

.control {
    border: none;
}

.groupActions {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
}

.log {
    margin: 200px auto;
    position: relative;
}

.error {
    border: 2px solid #FF6565;
}

.error-message {
    color: #FF6565;
    padding: .5em .2em;
    height: 1em;
    position: absolute;
    font-size: .8em;
    margin-bottom: 30px;
}

.groupPass {
    margin-top: 35px;
}

.navbar .navbar-expand-lg .navbar-light{
    background-color: #1e1e2d;
}

.dropdown-toggle::after {
    display: inline-block;
    color: white;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}